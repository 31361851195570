// extracted by mini-css-extract-plugin
export var skills = "Skills-module--skills--BPY+4";
export var skills_info = "Skills-module--skills_info--BZbg8";
export var skill_wall = "Skills-module--skill_wall--3RSFy";
export var skill_row = "Skills-module--skill_row--Zu+R5";
export var skill_brick = "Skills-module--skill_brick--BAgT7";
export var skill_brick__progress = "Skills-module--skill_brick__progress--CCaJN";
export var skill_name = "Skills-module--skill_name--0J-7l";
export var skill_level = "Skills-module--skill_level--wLg8I";
export var skills_list = "Skills-module--skills_list--wZmeR";
export var skill_bar = "Skills-module--skill_bar--AyMco";
export var skill_bar_selected = "Skills-module--skill_bar_selected--P0Efd";
export var skill_bar__progress = "Skills-module--skill_bar__progress--PHH4y";
export var skills_comment = "Skills-module--skills_comment--80500";